a { color: inherit; } 
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}

.submit-form {
  margin: auto;
}

.edit-form {
  max-width: 300px;
  margin: auto;
}


.hidden{
  display:none;
}

.navbar-brand{
  color:#2A2A2A;
  padding:5px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: bold;
}

.nav-bar .nav-bar-text{
  color: #959595;
}
.signout-btn{
  
  color: #2371E5;
  border-style: none;
  background-color: #f8f9fa;
}
.back-btn{
  
  color: #2371E5;
  border-style: none;
  background-color: #f8f9fa;
}
.navbar-light .nav-item{
  
  border-radius: 5px;
  padding-left: 5px;
  padding-right: 5px;
}

.navbar-light .nav-item.active .nav-link,
.navbar-light .nav-item .nav-link:active,
.navbar-light .nav-item .nav-link:focus{
    color: #2371E5;
    background-color: #dfe7f3;
    border-radius: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

.navbar-light .nav-item:hover .nav-link {
  color: #2371E5;
}


.list-group-item{
  border-style: solid;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 1px;
}
.checkbox {
  width: 25px; height: 25px;
}

.checkbox {
  display: none;
}
.woo {
  cursor: pointer;
}
.big-checkbox + #label_1_20_1:before {
  width: 25px;
  height: 25px;
  border-radius: 2px;
  box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
  background: #FFFFFF;
  position: relative;
  display: inline-block;
  margin-right: 1ex;
  content: "";
}

.big-checkbox:checked + #label_1_20_1:before {
  background: #2371E5;
}


.divided {
  display: flex;
  align-items: center;
}

.divider {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin: 5px
}

.check-text{
font-size: 10;
color: #2A2A2A;
text-align: center;
}

html {
  font-size: 14px;
}


.ticket-property-1{
margin-top:10px;
  padding: 10px;
  background-color: #f4f4f4;
  border-radius: 3px;
  width:100%;
}
.flat-list{
  width:100%;
  max-width: 100%;
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  text-align: left;
}


.flat-list-2{
  width:100%;
  max-width: 100%;
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  text-align: center;
}



.flat-list-3{
  width:100%;
  max-width: 100%;
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
  text-align: center;
}

.flat-list-item{
  margin-top: 10px;
}
.ticket-property-title{
  font-size: 12px;
  color:#8f8f8f;
}
.ticket-property-value{
  font-size: 17px;
  color:#2A2A2A;
}


.ticket-property-2{
  margin-top:10px;
    padding: 10px;
    border-top: 1px solid #8f8f8f;
    width:100%;
  }
.ticket-property-title-2{
  font-size: 12px;
  color:#8f8f8f;
}
.ticket-property-value-2{
  font-size: 12px;
  color:#2A2A2A;
}

.qrcode{
  width: 100%;
  max-width: 100%;
  aspect-ratio: 1/1;

  
  border-radius: 5px;
  border:#f4f4f4;
  border-width: 2px;
  border-style: solid;
}

.qr-scan-btn{
  width: 100%;
  max-width: 100%;
  aspect-ratio: 1/1;

  
}

.title-text{
  font-size: 17px;
  color:#2A2A2A;
  text-align: left;
}
.shoe-image{
  width: 100%;
  max-width: 100%;

  
  border-radius: 5px;
  border:#f4f4f4;
  border-width: 2px;
  border-style: solid;
}


.delete-btn{
  font-size: 17px;
  color:#2A2A2A;
  text-align: center;
  border-radius: 5px;
  border:#000000;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #FFFFFF;
}


.photo-btn{
  font-size: 17px;
  color:#2A2A2A;
  text-align: center;
  border-radius: 5px;
  border:#000000;
  border-style: solid;
  border-width: 2px;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #FFFFFF;
}

.submit-btn{
  font-size: 17px;
  color:#ffffff;
  text-align: center;
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  background-color: #2371E5;
}

.image-btn{

  background-color: #FFFFFF;
  border-style: none;
}

.sub-title{
  color: #4a4a4a;
  font-size: 17px;
}

h4.title{
  color:#000000;
  font-size: 32px;
}
h4{
  font-weight: bold;
  font-size: 32px;
}

.dropdown-parent{
  width: 100% !important;
  max-width: 100% !important;
  margin: 0;
  padding: 0;
}
.dropdown-toggle{
width: 100% !important;
  max-width: 100% !important;
  color: #4a4a4a;
  font-size: 17px;
  width: 100%;
  background-color: #ffffff;
}
.dropdown-item{
  width: 100%;
  max-width: 100% !important;
  color: #000000;
  font-size: 17px;
  
  background-color: #ffffff;
}


.dropdown-menu{
  width: 600px;
  max-width: 600px !important;
  color: #000000;
  font-size: 17px;
  
  background-color: #ffffff;
}

.small-title{
  padding-top: 15px;
  font-size: 14px;
  font-weight: 500;
}


input.form-control{
  height: 50px !important;
}

select.form-select{
  height: 50px !important;
}


.grey-box{
  background-color: #f4f4f4;
}


.grey-check{
  margin: 10px;
  line-height: 28px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-size: 12px;
  padding: 0;
  border-color: #000000;
  width:100%;
}
.grey-check-disabled{
  margin: 10px;
  line-height: 28px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-size: 12px;
  padding: 0;
  border-color: #CCC;
  width:100%;
}

.user-title{
  background-color: #f4f4f4;
  padding:10px;
  font-weight: 700;
  font-size: 14px;
}
#item1{
  
  font-weight: 700;
  font-size: 14px;
}
#item2{
  
  font-weight: 900;
  font-size: 20px;
}

.form-check {
  position: relative;
}

.white-stats{
  color:white;
  width:100%;
  height:auto;
  background-color: #2371E5;
  border-radius: 3px;
  padding: 20px;
}
.white-stats h1{
  font-size: 34px;
}
.white-stats h5{
  font-size: 14px;
}
.grey-stats h1{
  font-size: 34px;
}
.grey-stats h5{
  font-size: 14px;
}

.grey-stats{
  color:#000;
  width:100%;
  height:auto;
  background-color: #f4f4f4;
  border-radius: 3px;
  padding: 20px;
}



.grey-check input {
  display: none;
}
.grey-check-disabled input {
  display: none;
}

.grey-check .checkbox-indicator {
  content: "\2713";
  color: #000;
  display: block;
  height: 18px;
  width: 18px;
  border: 1px solid #CCC;
  border-radius: 3px;
  float: left;
  margin: 5px;
  background-color: #ffffff;
 
  
}
.grey-check-disabled .checkbox-indicator {
  content: "\2713";
  color: #CCC;
  display: block;
  height: 18px;
  width: 18px;
  border: 1px solid #CCC;
  border-radius: 3px;
  float: left;
  margin: 5px;
  background-color: #f4f4f4;
 
  
}

.disabled{
  
  
}
.grey-check .tick-mark-2{
  display: none;
  color: #000;
  height: 100%;
  width: 80%;
  text-align: center;
  opacity: 1;
  font-weight: bold;
  margin-top: -5px;
  margin-left:1px;
  
}
.grey-check-disabled .tick-mark-2{
  display: none;
  color: #CCC;
  height: 100%;
  width: 80%;
  text-align: center;
  opacity: 1;
  font-weight: bold;
  margin-top: -5px;
  margin-left:1px;
  
}
.grey-check .grey-check-2 {
  display: block;
  height: 100%;
  width: 100%;
}

.grey-check-disabled .grey-check-2 {
  background-color: #f4f4f4;
  color:#CCC;
  display: block;
  height: 100%;
  width: 100%;
}

.grey-check .checkbox-indicator:hover {
  border: 1px solid #777;
}


.grey-check input:checked ~ .grey-check-2 .tick-mark-2 {
  display: block;
  }

  .grey-check-disabled input:checked ~ .grey-check-2 .tick-mark-2 {
    display: block;
    }
.grey-check input:checked ~ .grey-check-2{
  color: #ffffff;
  background-color: #2371E5;
}

.grey-check-disabled input:checked ~ .grey-check-2{
  color: #CCC;
  background-color: #f4f4f4;
}

